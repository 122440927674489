import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAuth } from '../contexts/Auth';
import { isDelegato, isSegreteriaNazionale } from '../libs/utils/auth';
import { formatCurrency } from '../libs/utils/formatters/currency';
import BalanceTable from '../pages/Corsi/Tables/BalanceTable';
import HeadquarterBalanceTable from '../pages/Corsi/Tables/HeadquarterBalanceTable';
import UnderlineButton from './Buttons/UnderlineButton';
import InputPresentational from './Form/InputPresentational';
import PriceInputPresentational from './Form/PriceInputPresentational';
import RadioGroupPaymentCourse from './Form/RadioGroupPaymentCourse';
import StaticInputPresentational from './Form/StaticInputPresentational';
import H4SpacingLetter from './Typography/H4SpacingLetter';
import { DownloadIcon } from '@heroicons/react/outline';
import { CourseStatus } from '../interfaces/commons';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import AlertMessage from './AlertMessage';
import { CourseRequest } from '../interfaces/courses';
import { ParticipantStatus } from '../interfaces/participants';
import { useMutation } from 'react-query';
import coursesAPIs from '../api/courses';
import { useParams } from 'react-router-dom';
import {
  calcoloTotSpeseSedeSede,
  calcoloTotSpeseSezioneSede,
  calcoloTotSpeseSezioneSezione,
  getCourseSubscriptionLimit,
} from '../libs/utils/helpers';
import CustomSwitch from './Form/CustomSwitch';
import BaseModal from './Modals/BaseModal';

interface FormCostiEventiProps {
  estimatedIncome: number;
  estimatedOutflow: number;
  finalIncome: number;
  finalOutflow: number;
  estimatedHeadquarterOutflow: number;
  estimatedHeadquarterIncome: number;
  finalHeadquarterOutflow: number;
  finalHeadquarterIncome: number;
  disabled?: boolean;
  courseStatus?: CourseStatus;
  safetyMargin?: number;
  disableEditConsuntivo: boolean;
}

const FormCostiCorso: React.FC<FormCostiEventiProps> = ({
  estimatedIncome,
  estimatedOutflow,
  finalIncome,
  finalOutflow,
  estimatedHeadquarterOutflow,
  estimatedHeadquarterIncome,
  finalHeadquarterIncome,
  finalHeadquarterOutflow,
  disabled,
  courseStatus,
  safetyMargin,
  disableEditConsuntivo,
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<CourseRequest>();
  const [{ profile, settings, token }] = useAuth();
  const { id: courseId } = useParams();

  const priceLimit = useMemo(() => {
    const balance = watch('balance');
    const headquarterBalance = watch('headquarterBalance');
    const minParticipants = watch('minParticipants');
    const initialSubscriptionAmount = watch('initialSubscriptionAmount') || 0;
    return getCourseSubscriptionLimit(
      balance,
      headquarterBalance,
      minParticipants,
      initialSubscriptionAmount
    );
  }, [watch('minParticipants'), watch('balance.contributes.estimatedIncome')]);

  const [limitDebounce] = useDebounce(priceLimit, 1000);
  const [showAlert, setShowAlert] = useState(false);
  const [confirmDisableSubscriptionModal, setConfirmDisableSubscriptionModal] =
    useState(false);
  const totalConfirmedParticipants = useMemo(
    () =>
      watch('partecipations')?.data.filter(
        (elem) => elem.attributes.status === ParticipantStatus.CONFIRMED
      ).length || 0,
    [watch('partecipations')]
  );

  const speseSedeSede = useMemo(
    () => calcoloTotSpeseSedeSede(watch('headquarterBalance')),
    [
      estimatedIncome,
      estimatedHeadquarterIncome,
      finalIncome,
      finalHeadquarterOutflow,
    ]
  );

  const speseSezioneSezione = useMemo(
    () => calcoloTotSpeseSezioneSezione(watch('balance')),
    [
      estimatedIncome,
      estimatedHeadquarterIncome,
      finalIncome,
      finalHeadquarterOutflow,
    ]
  );

  const speseSezioneSede = useMemo(
    () => calcoloTotSpeseSezioneSede(watch('balance')),
    [
      estimatedIncome,
      estimatedHeadquarterIncome,
      finalIncome,
      finalHeadquarterOutflow,
    ]
  );

  const utilePreventivato = useMemo(() => {
    return (
      Number(estimatedIncome || 0) -
      (Number(estimatedOutflow || 0) + Number(estimatedHeadquarterOutflow || 0))
    );
  }, [
    estimatedIncome,
    estimatedHeadquarterIncome,
    finalIncome,
    finalHeadquarterOutflow,
  ]);

  const utileConsuntivato = useMemo(
    () =>
      finalIncome - (Number(finalOutflow) + Number(finalHeadquarterOutflow)),
    [
      estimatedIncome,
      estimatedHeadquarterIncome,
      finalIncome,
      finalHeadquarterOutflow,
    ]
  );

  const totSpese = useMemo(
    () => speseSedeSede + speseSezioneSede + speseSezioneSezione,
    [speseSedeSede, speseSezioneSede, speseSezioneSezione]
  );

  const subscriptionQuote = watch(
    'headquarterBalance.administrations.estimatedPriceUnit'
  );

  const TransferSum = watch('subscriptionAmount')
    ? (watch('subscriptionAmount') || 0) + (subscriptionQuote || 0)
    : (watch('initialSubscriptionAmount') || 0) + (subscriptionQuote || 0);

  const TransferMarkup = TransferSum * (settings?.bankTransferFee || 0.2);
  const bankTransferTotal = Math.ceil(TransferSum + TransferMarkup);

  const onChangeSubscriptionAmount = (value: number) => {
    if (watch('status') !== CourseStatus.DRAFT) return;
    if (Number(value) < priceLimit) {
      setShowAlert(true);
      toast.error(
        "La quota corso non può essere minore dell'importo suggerito"
      );
      setValue('subscriptionAmount', priceLimit);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return;
    }
    if (Number(value) >= priceLimit) {
      setValue('subscriptionAmount', Math.ceil(Number(value)));
    }
  };

  const { mutate: downloadBalanceCSVMutation } = useMutation(
    ['downloadBalanceCSV'],
    coursesAPIs.downloadCostsCSV,
    {
      onSuccess: () => {
        toast.success('Operazione avvenuta con successo');
      },
      onError: (error: any) => {
        toast.error(
          error.response?.data?.error?.message ||
            'Ooops... Qualcosa è andato storto'
        );
      },
    }
  );

  useEffect(() => {
    if (Number(watch('subscriptionAmount')) > 0) {
      onChangeSubscriptionAmount(watch('subscriptionAmount'));
    } else {
      setValue(
        'subscriptionAmount',
        Number(watch('initialSubscriptionAmount') || 0)
      );
    }
  }, [limitDebounce]);

  useEffect(() => {
    if (
      watch('maxParticipants') < Number(watch('initialMaxParticipants')) &&
      isDelegato(profile) &&
      watch('status') !== CourseStatus.DRAFT
    ) {
      setError('maxParticipants', {
        message: 'Puoi solo incrementare il numero massimo di partecipanti!',
      });
    } else {
      clearErrors('maxParticipants');
    }
  }, [watch('maxParticipants')]);

  const toggleConfirmDisableSubscriptions = () =>
    setConfirmDisableSubscriptionModal((v) => !v);

  return (
    <div className='pt-6 pb-8 space-y-8'>
      <div className='space-y-4'>
        <H4SpacingLetter>Numero di partecipanti</H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4'>
          <InputPresentational
            id='minParticipants'
            label='Numero minimo di partecipanti'
            name='minParticipants'
            type='number'
            register={register}
            disabled={disabled}
            error={errors.minParticipants}
          />
          <InputPresentational
            id='maxParticipants'
            label='Numero massimo di partecipanti'
            name='maxParticipants'
            type='number'
            disabled={
              watch('status') === CourseStatus.CANCELED ? disabled : false
            }
            register={register}
            error={errors.maxParticipants}
          />
        </div>
      </div>
      <div className='space-y-4'>
        <H4SpacingLetter>Numero di partecipanti CONFERMATI</H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4'>
          <InputPresentational
            type='number'
            defaultValue={totalConfirmedParticipants}
            disabled
          />
        </div>
        <CustomSwitch
          checked={!!watch('disableSubscriptions')}
          onChange={() => {
            toggleConfirmDisableSubscriptions();
          }}
          label='Disabilita iscrizioni'
        />
        <BaseModal
          isOpen={confirmDisableSubscriptionModal}
          toggle={toggleConfirmDisableSubscriptions}
          onConfirm={() => {
            toggleConfirmDisableSubscriptions();
            setValue('disableSubscriptions', !watch('disableSubscriptions'));
          }}
          title={`${
            watch('disableSubscriptions') ? 'Attivazione' : 'Sospensione'
          } iscrizioni evento`}
          subtitle={`Sei sicuro di voler <strong>${
            watch('disableSubscriptions') ? 'attivare' : 'sospendere'
          }</strong> le iscrizioni per il corso ${watch(
            'title'
          )}? </br>Questa variazione sarà valida solo dopo il salvataggio del corso.`}
          onCancel={toggleConfirmDisableSubscriptions}
        />
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>Liquidi</H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4'>
          <InputPresentational
            id='glassesForBottle'
            label='Numero bicchieri per bottiglia'
            name='glassesForBottle'
            type='number'
            register={register}
            disabled={disabled}
          />
          <InputPresentational
            id='wineTypes'
            label='Numero tipologie di vino'
            name='wineTypes'
            type='number'
            register={register}
            disabled={disabled}
          />
          <PriceInputPresentational
            id='bottlePrice'
            label='Prezzo medio a bottiglia in €'
            name='bottlePrice'
            type='number'
            register={register}
            disabled={disabled}
          />
        </div>
      </div>
      <div className='space-y-4'>
        <div className='flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between gap-4'>
          <H4SpacingLetter>Bilancio</H4SpacingLetter>
          {courseId && isSegreteriaNazionale(profile) && (
            <UnderlineButton
              onClick={() => {
                downloadBalanceCSVMutation({
                  token,
                  id: courseId,
                  fileName: `BILANCIO CORSO ${watch(
                    'title'
                  )}_${new Date().getTime()}`,
                });
              }}
            >
              <DownloadIcon className='w-4 h-4' /> Scarica CSV bilancio
            </UnderlineButton>
          )}
        </div>

        <BalanceTable
          type='corso'
          estimatedOutflow={estimatedOutflow || 0}
          estimatedIncome={estimatedIncome}
          finalOutflow={finalOutflow}
          finalIncome={finalIncome}
          disabled={disabled && disableEditConsuntivo}
          //per isCoursePublished si intende se il corso è stato pubblicato o consuntivato
          isCoursePublished={[
            CourseStatus.PUBLISHED,
            CourseStatus.FINAL_BALANCE_DELEGATION,
            CourseStatus.FINAL_BALANCE_NATIONAL,
          ].includes(watch('status'))}
        />
      </div>
      {isSegreteriaNazionale(profile) && (
        <div className='space-y-4'>
          <H4SpacingLetter>Spese della sede</H4SpacingLetter>
          <HeadquarterBalanceTable
            estimatedOutflow={estimatedHeadquarterOutflow}
            estimatedIncome={estimatedHeadquarterIncome}
            finalOutflow={finalHeadquarterOutflow}
            disabled={disabled && disableEditConsuntivo}
            safetyMargin={safetyMargin}
            estimatedBalanceOutlfow={estimatedOutflow}
            //per isCoursePublished si intende se il corso è stato pubblicato o consuntivato
            isCoursePublished={[
              CourseStatus.PUBLISHED,
              CourseStatus.FINAL_BALANCE_DELEGATION,
              CourseStatus.FINAL_BALANCE_NATIONAL,
            ].includes(watch('status'))}
          />
        </div>
      )}
      <div className='space-y-4'>
        <H4SpacingLetter>Quota corso</H4SpacingLetter>
        <p className='mt-1 mb-0 italic text-red-400 text-xs'>
          Al prezzo unitario va aggiunto, se dovuto, il valore della tessera
          dell'anno in corso
        </p>

        <div className='grid md:grid-cols-3 items-start gap-4'>
          <div className='space-y-4'>
            <PriceInputPresentational
              id='subscriptionAmount'
              label='Quota corso unitaria GIÀ SCONTATA'
              name='subscriptionAmount'
              type='number'
              value={watch('subscriptionAmount')}
              onChange={(e) =>
                setValue('subscriptionAmount', Number(e.target.value))
              }
              onBlur={() =>
                onChangeSubscriptionAmount(watch('subscriptionAmount'))
              }
              disabled={disabled || courseStatus === CourseStatus.PUBLISHED}
              error={errors.subscriptionAmount}
            />
            {showAlert && (
              <AlertMessage
                borderLeft
                noTitle
                text={`La quota corso non può essere minore di ${priceLimit}`}
              />
            )}
          </div>
          <PriceInputPresentational
            id=''
            label='Prezzo unitario di vendita'
            name=''
            type='number'
            value={bankTransferTotal}
            disabled
          />
          {/* <div className='md:col-span-2'>
            <p className='mb-0 text-gray-500 text-sm italic'>
              * Il prezzo unitario corrisponde al prezzo minimo pagato con
              carta.
              <br />
              {`Ogni pagamento diverso dal pagamento con carte subirà una
              maggiorazione del ${(settings?.bankTransferFee || 0) * 100}%.`}
            </p> */}
          {/* inserire la percentuale corretta della maggiorazione */}
          {/* </div> */}
        </div>
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>Spesa amministrativa</H4SpacingLetter>

        <div className='grid md:grid-cols-3 items-end gap-4'>
          <PriceInputPresentational
            id='headquarterBalance.administrations.estimatedPriceUnit'
            label='Spesa amministrativa unitaria'
            name='headquarterBalance.administrations.estimatedPriceUnit'
            type='number'
            register={register}
            readOnly
            disabled
          />
        </div>
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>Modalità di pagamento</H4SpacingLetter>
        <p className='mt-1 mb-0 italic text-red-400 text-xs'>
          Al prezzo unitario va aggiunto, se dovuto, il valore della tessera
          dell'anno in corso
        </p>
        <RadioGroupPaymentCourse isCourseCanceled={disabled} />
      </div>

      <div className='space-y-4 bg-neutral-100 px-3 py-4'>
        <H4SpacingLetter>Andamento del corso</H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
          <StaticInputPresentational
            big
            label='Margine preventivo'
            text={formatCurrency(utilePreventivato)}
          />
          <StaticInputPresentational
            big
            label='Margine consuntivo'
            text={formatCurrency(Number(utileConsuntivato))}
          />
          <StaticInputPresentational
            big
            label='Scostamento Margine'
            text={formatCurrency(utileConsuntivato - utilePreventivato)}
          />
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
          {
            <StaticInputPresentational
              big
              label='Tot spese di SEZIONE che evade la SEDE'
              text={formatCurrency(speseSezioneSede)}
            />
          }
          <StaticInputPresentational
            big
            label='Tot spese di SEZIONE che evade la SEZIONE'
            text={formatCurrency(speseSezioneSezione)}
          />
          {
            <StaticInputPresentational
              big
              label='Tot spese della SEDE che evade la SEDE'
              text={formatCurrency(speseSedeSede)}
            />
          }
          {
            <StaticInputPresentational
              big
              label='Totale spese'
              text={formatCurrency(totSpese)}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default FormCostiCorso;

import { AtSymbolIcon, PhoneIcon } from '@heroicons/react/solid';
import packageJson from '../../package.json';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className='px-4 py-12 text-sm border-t-2 border-gray-200 divide-y  bg-neutral-50 lg:px-10'>
      <div className='grid items-center w-full gap-8 pb-8 md:grid-cols-3'>
        <div className='flex flex-col items-center md:items-start'>
          <div className='flex items-center gap-3 mb-3'>
            <AtSymbolIcon className='w-5 h-5 text-gray-300' />
            <Link
              to='mailto:segreteria@onav.it'
              className='text-gray-500 hover:text-primary'
            >
              segreteria@onav.it
            </Link>
          </div>
          <div className='flex items-center gap-3'>
            <PhoneIcon className='w-5 h-5 text-gray-300' />
            <Link
              to='tel:+39 0141 530313'
              className='text-gray-500 hover:text-primary'
            >
              +39 0141 530313
            </Link>
          </div>
        </div>

        <div className='space-y-4 text-center'>
          <p className='text-gray-600 text-medium'>
            Sede Legale:
            <br />
            <span className='font-light text-gray-400'>
              Piazza Medici 8, 14100 Asti (AT)
            </span>
          </p>
          <p className='text-gray-600 text-medium'>
            Sede Operativa:
            <br />
            <span className='font-light text-gray-400'>
              Piazza Santa Maria Nuova 5, 14100 Asti (AT)
            </span>
          </p>
        </div>

        <img
          className='w-auto mx-auto h-14 md:ml-auto md:mr-0'
          src='/images/logo-onav-orizzontale.svg'
          alt='Onav Organizzazione Nazionale Assaggiatori Vino'
          title='Onav Organizzazione Nazionale Assaggiatori Vino'
        />
      </div>

      <div className='flex flex-col items-center justify-center gap-2 pt-8 text-gray-500 lg:flex-row lg:gap-0'>
        <p>© 2022 ONAV - Organizzazione nazionale assaggiatori di vino</p>
        <div className='hidden w-px h-6 mx-5 bg-gray-200 lg:block' />
        <Link to='/informazioni-legali' className='hover:text-primary'>
          Informazioni Legali
        </Link>
        <div className='hidden w-px h-6 mx-5 bg-gray-200 lg:block' />
        <Link
          to='/documents/adempimento-obbligo-trasparenza.pdf'
          target='_blank'
          className='hover:text-primary'
        >
          Adempimenti L 124/2017
        </Link>
        <div className='hidden w-px h-6 mx-5 bg-gray-200 lg:block' />
        <p>v. {packageJson.version}</p>
        <div className='hidden w-px h-6 mx-5 bg-gray-200 lg:block' />
        <a
          href='https://palmabit.notion.site/Rilasci-e-Ticket-assistenza-40502fee59fd47aaa30af9635a756218?pvs=4'
          target='_blank'
          className='hover:text-primary'
        >
          Rilasci e Ticket assistenza
        </a>
      </div>
    </footer>
  );
}
